<template>
  <div :style="{width: '100%', height: `${height}px`, backgroundColor: 'rgba(0,0,0,0.8)'}" ref="videoPlayerRef" >

    <div v-if="useSystemFullIndex === -1" :style="{width: '50px', height: '50px', opacity: 1, position: 'absolute', right: 0 , bottom: 0, zIndex: 99999}" @click="handleFullScreen"></div>
  </div>
</template>

<script>

let player = null;
export default {
  name: 'VideoPlayer',
  components:{

  },
  data() {
    return {
      height: 0,
      useSystemFullIndex: 0,
    };
  },
  mounted() {
    const url = window.location.search; //获取url中"?"符后的字符串
    // const url = '?playUrl=wss://v.zzyunyi.cn:41002/live?app=live&port=41000&stream=34020000001180006424_0_0&session=4518fadb46d445e590cd7f714958a88d&type=0&deviceId=34020000001180006424&channelNum=0&streamType=0&httpPort=8081&resolve=0&username=1508336911373045762&height=400'
    const urlIndex = url.indexOf('?playUrl') || 0;
    const heightIndex = url.indexOf('&height') || 0;
    const fullScreenIndex = url.indexOf('&screen_orientation') || -1;
    this.useSystemFullIndex = url.indexOf('&use_system_full');
    if (heightIndex > 0) {
      const heightStr = fullScreenIndex > -1 ? url.slice(heightIndex + 8, fullScreenIndex) : this.useSystemFullIndex > 1 ? url.slice(heightIndex + 8, this.useSystemFullIndex) : url.slice(heightIndex + 8);
      this.height = Number(heightStr || 0);
      this.initVideoPlayer(url.slice(urlIndex + 9, heightIndex), fullScreenIndex);
    }
  },
  unmounted() {
    this.destroy();
  },
  methods: {
    destroy() {
      player && player.destroy();
    },
    handleFullScreen() {
      window.onFullScreen.postMessage(1);
    },
    initVideoPlayer(url, fullScreenIndex) {
      player = new window.Jessibuca({
        container: this.$refs.videoPlayerRef,
        videoBuffer: 0.2, // 缓存时长
        isResize: false,
        text: "",
        isFlv: true,
        loadingText: "加载中",
        decoder: "/jessibuca/decoder.js",
        debug: false,
        loadingTimeout: 60,
        loadingTimeoutReplay: true,
        timeout: 60,
        heartTimeout: 60,
        heartTimeoutReplay: true,
        showBandwidth: false, // 显示网速
        useWebFullScreen: fullScreenIndex > -1,
        operateBtns: {
          audio: true,
          fullscreen: true
        },
        hasAudio: true
      });
      player.play(url);
      fullScreenIndex > -1 && player.setFullscreen(true);
      player.on("fullscreen", function (flag) {
        window.onFullScreen.postMessage(flag ? 1 : 0);
      })
    }
  }
}
</script>

<style>
</style>
